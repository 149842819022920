a.paginateLink {
    color:#fff;

    &.nextLink {
        float:right;
    }

    &.prevLink {
        float:left;
    }
}

.pagination {
    float:left;
    width: 100%;

    .inner {
        max-width: 600px;
        width: 100%;
        float:left;
        clear:both;
    }
}

.leftColumn.media {
    position: fixed;
    left:0px;
    top:134px;

    @media(max-width: 1023px) {
        position: relative;
        top:0px;
    }
}

.rightColumn.media {
    padding-top:75px;
    padding-bottom: 100px;
    padding-right: 20px;

    @media(max-width: 1023px) {
        padding-top:0px;
        padding-left:20px;
    }

    article {
        width: 100%;
        float:left;
        clear:both;
        margin-bottom: 30px;

        span.inner {
            max-width: 600px;
            width: 100%;
            float:left;
            clear:both;
            padding-bottom: 20px;
            border-bottom:1px solid #fff;

            .featureImage {
                float:left;
                width: 100%;
                margin-bottom: 20px;
            }

            .baseLinks {
                float:left;
                width: 100%;

                a {
                    float:left;
                    clear:both;
                    color:#fff;
                    position: relative;

                    &:after {
                        position: absolute;
                        top:6px;
                        right:-15px;
                        font-size: 12px;
                        content: "\edbb";
                        font-family: 'skeleticons';

                        @include transition(all,500ms,ease);
                    }

                    &:hover {
                        &:after {
                            right:-20px;
                        }
                    }
                }
            }
        }
    }
}
