@font-face {
    font-family: "Apercu";
    src: url("fonts/Apercu-Bold.eot");
    src: url("fonts/Apercu-Bold.eot?#iefix") format("embedded-opentype"),
         url("fonts/Apercu-Bold.woff2") format("woff2"),
         url("fonts/Apercu-Bold.woff") format("woff"),
         url("fonts/Apercu-Bold.ttf") format("ttf"),
         url("fonts/Apercu-Bold.svg#Apercu") format("svg");
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: "Apercu";
    src: url("fonts/Apercu-Light.eot");
    src: url("fonts/Apercu-Light.eot?#iefix") format("embedded-opentype"),
         url("fonts/Apercu-Light.woff2") format("woff2"),
         url("fonts/Apercu-Light.woff") format("woff"),
         url("fonts/Apercu-Light.ttf") format("ttf"),
         url("fonts/Apercu-Light.svg#Apercu") format("svg");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "Apercu";
    src: url("fonts/Apercu-Medium.eot");
    src: url("fonts/Apercu-Medium.eot?#iefix") format("embedded-opentype"),
         url("fonts/Apercu-Medium.woff2") format("woff2"),
         url("fonts/Apercu-Medium.woff") format("woff"),
         url("fonts/Apercu-Medium.ttf") format("ttf"),
         url("fonts/Apercu-Medium.svg#Apercu") format("svg");
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: "Apercu-Mono";
    src: url("fonts/Apercu-Mono.eot");
    src: url("fonts/Apercu-Mono.eot?#iefix") format("embedded-opentype"),
         url("fonts/Apercu-Mono.woff2") format("woff2"),
         url("fonts/Apercu-Mono.woff") format("woff"),
         url("fonts/Apercu-Mono.ttf") format("ttf"),
         url("fonts/Apercu-Mono.svg#Apercu-Mono") format("svg");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "Apercu";
    src: url("fonts/Apercu-Regular.eot");
    src: url("fonts/Apercu-Regular.eot?#iefix") format("embedded-opentype"),
         url("fonts/Apercu-Regular.woff2") format("woff2"),
         url("fonts/Apercu-Regular.woff") format("woff"),
         url("fonts/Apercu-Regular.ttf") format("ttf"),
         url("fonts/Apercu-Regular.svg#Apercu") format("svg");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "ITCAvantGardeStd";
    src: url("fonts/ITCAvantGardeStd-Bold.eot");
    src: url("fonts/ITCAvantGardeStd-Bold.eot?#iefix") format("embedded-opentype"),
         url("fonts/ITCAvantGardeStd-Bold.woff2") format("woff2"),
         url("fonts/ITCAvantGardeStd-Bold.woff") format("woff"),
         url("fonts/ITCAvantGardeStd-Bold.ttf") format("ttf"),
         url("fonts/ITCAvantGardeStd-Bold.svg#ITCAvantGardeStd") format("svg");
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: "ITCAvantGardeStd";
    src: url("fonts/ITCAvantGardeStd-Demi.eot");
    src: url("fonts/ITCAvantGardeStd-Demi.eot?#iefix") format("embedded-opentype"),
         url("fonts/ITCAvantGardeStd-Demi.woff2") format("woff2"),
         url("fonts/ITCAvantGardeStd-Demi.woff") format("woff"),
         url("fonts/ITCAvantGardeStd-Demi.ttf") format("ttf"),
         url("fonts/ITCAvantGardeStd-Demi.svg#ITCAvantGardeStd") format("svg");
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: "JerseyM54";
    src: url("fonts/JerseyM54.eot");
    src: url("fonts/JerseyM54.eot?#iefix") format("embedded-opentype"),
         url("fonts/JerseyM54.woff2") format("woff2"),
         url("fonts/JerseyM54.woff") format("woff"),
         url("fonts/JerseyM54.ttf") format("ttf"),
         url("fonts/JerseyM54.svg#JerseyM54") format("svg");
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: "Rift";
    src: url("fonts/Rift-Bold.eot");
    src: url("fonts/Rift-Bold.eot?#iefix") format("embedded-opentype"),
         url("fonts/Rift-Bold.woff2") format("woff2"),
         url("fonts/Rift-Bold.woff") format("woff"),
         url("fonts/Rift-Bold.ttf") format("ttf"),
         url("fonts/Rift-Bold.svg#Rift") format("svg");
    font-style: normal;
    font-weight: bold;
}
@font-face {
    font-family: "Rift";
    src: url("fonts/Rift-Demi.eot");
    src: url("fonts/Rift-Demi.eot?#iefix") format("embedded-opentype"),
         url("fonts/Rift-Demi.woff2") format("woff2"),
         url("fonts/Rift-Demi.woff") format("woff"),
         url("fonts/Rift-Demi.ttf") format("ttf"),
         url("fonts/Rift-Demi.svg#Rift") format("svg");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "skeleticons";
    src: url("fonts/skeleticons.eot");
    src: url("fonts/skeleticons.eot?#iefix") format("embedded-opentype"),
         url("fonts/skeleticons.woff2") format("woff2"),
         url("fonts/skeleticons.woff") format("woff"),
         url("fonts/skeleticons.ttf") format("ttf"),
         url("fonts/skeleticons.svg#skeleticons") format("svg");
    font-style: normal;
    font-weight: 300;
}