main.home {

    section.slider {
        display: block;
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        overflow: hidden;
        background: url(images/default.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 60px auto;

        .flex-control-nav {
            right: 50px;
            position: fixed;
            top: 50%;
            width: 4px;
            bottom:auto;
            z-index: 998;

            @media(max-width: 767px) {
                position: absolute;
                top:50%;
            }

            li {
                display: inline-block;
                margin:4px 6px;
            }
        }

        .flex-control-paging li a {
            background: #fff none repeat scroll 0 0;
            border: medium none;
            box-shadow: none;
            height: 60px;
            width: 4px;
            border-radius: 0px;

            @media(max-width: 767px) {
                height:30px;
            }

            &.flex-active {
                background-color: #638792;
            }
        }

        ul.slides {
            float:left;
            width: 100%;
            height:100%;

            li {
                float:left;
                width: 100%;
                height:100%;
                background-size: cover;
                background-position: center center;
                display: none;

                &.flex-active-slide {
                    .textBlock {
                        transform:scale(1) translateY(0px);
                        -webkit-transform:scale(1) translateY(0px);
                    }
                }

                .textBlock {
                    position: absolute;
                    bottom:60px;
                    left:20px;
                    padding:15px;
                    color:#fff;
                    font-size: 16px;
                    line-height: 1.2;
                    background:rgba(89,89,89,0.8);

                    transform: scale(0.95) translateY(1vw);
                    -webkit-transform: scale(0.95) translateY(1vw);
                    @include transition(all,2000ms,ease);

                    @media(max-width: 767px) {
                        bottom:40px;
                        left:0px;
                        width: 100%;
                    }

                    strong {
                        font-weight: 600;
                    }

                    > span {
                        float:left;
                        clear:both;

                        &.base {
                            margin-top: 5px;
                            font-size: 15px;

                            span.arrow {
                                font-family: "icons";
                                position: relative;
                                margin-left: 10px;
                                color:#638792;
                                position: relative;
                                top:2px;

                                &:before {
                                    content: "\E001";
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
