@font-face {
	font-family: "icons";
	src: url('fonts/icons.eot');
	src: url('fonts/icons.eot?#iefix') format('eot'),
		url('fonts/icons.woff2') format('woff2'),
		url('fonts/icons.woff') format('woff'),
		url('fonts/icons.ttf') format('truetype'),
		url('fonts/icons.svg#icons') format('svg');
}

.icon:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-arrow-right:before {
	content: "\E001";
}

.icon-chat:before {
	content: "\E002";
}

.icon-circle-cross:before {
	content: "\E003";
}

.icon-circle-minus:before {
	content: "\E004";
}

.icon-circle-plus:before {
	content: "\E005";
}

.icon-comment:before {
	content: "\E006";
}

.icon-cross:before {
	content: "\E007";
}

.icon-down-arrow:before {
	content: "\E008";
}

.icon-down:before {
	content: "\E009";
}

.icon-email:before {
	content: "\E00A";
}

.icon-facebook:before {
	content: "\E00B";
}

.icon-google-plus:before {
	content: "\E00C";
}

.icon-instagram:before {
	content: "\E00D";
}

.icon-left-arrow:before {
	content: "\E00E";
}

.icon-like-icon:before {
	content: "\E00F";
}

.icon-like:before {
	content: "\E010";
}

.icon-lines:before {
	content: "\E011";
}

.icon-nav:before {
	content: "\E012";
}

.icon-play:before {
	content: "\E013";
}

.icon-right-arrow:before {
	content: "\E014";
}

.icon-right:before {
	content: "\E015";
}

.icon-search:before {
	content: "\E016";
}

.icon-share:before {
	content: "\E017";
}

.icon-tag:before {
	content: "\E018";
}

.icon-twitter:before {
	content: "\E019";
}
