body.project {

    main {
        overflow-x: visible;
    }
}

section.project {
    float:left;
    position: relative;
    margin-top:0px;
    min-height: 100%;
    left:0px;
    width:400px;
    z-index: 2;
    @include transition(all,500ms,ease);

    @media(max-width: 767px) {
        width: 100%;
    }

    &.active {

        left:-400px;

        .column {

            .collapse {
                left:0px;

                .collapseLeft {
                    display: inline;
                }

                .collapseRight {
                    display: none;
                }
            }
        }
    }

    .column {
        float:left;
        width:400px;
        left:0px;
        position: absolute;
        min-height: 100%;
        background:rgba(89,89,89,0.8);
        padding:40px 20px;
        @include transition(all,500ms,ease);

        @media(max-width: 767px) {
            width: 100%;
        }

        .collapse {
            position: fixed;
            top:180px;
            left:380px;
            z-index: 999;
            background: #fff;
            width: 20px;
            text-align: center;
            cursor: pointer;

            @media(max-width: 767px) {
                display: none;
            }

            @include transition(all,500ms,ease);

            span {
                color:#12172a;
            }

            .collapseLeft {
                display: none;
            }
        }

        .content {

            float:left;
            width: 100%;

            > span {
                float:left;
                width: 100%;
                display:none;

                &.active {
                    display: block;
                }

                span.block {
                    float:left;
                    width: 100%;

                    span {
                        float:left;
                        width: 100%;

                        &.title {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .projectNav {
            float:left;
            width: 100%;
            margin-bottom: 20px;

            a {
                background-color:#fff;
                display: inline-block;
                border-radius: 5px;
                text-transform: uppercase;
                font-size: 11px;
                line-height: 20px;
                padding: 0px 5px;
                margin-right: 5px;
                position: relative;

                @include transition(all,500ms,ease);

                &:after {
                    position: absolute;
                    bottom:-5px;
                    left:50%;
                    margin-left:-4px;
                    width: 0;
                    height: 5px;
                    content:'';
                    border-style: solid;
                    border-width: 5px 4px 0 4px;
                    opacity: 0;
                    border-color: #638792 transparent transparent transparent;

                    @include transition(all,500ms,ease);
                }

                &.active {
                    color:#fff;
                    background:#638792;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        h1 {

            margin-bottom: 15px;

            &:after {
                /*display: none;*/
            }
        }

        h2 {
            font-size: 16px;

            &:after {
                display: none;
            }
        }
    }
}

section.projectSlider {
    float:left;
    width:100%;
    position: absolute;
    top:134px;
    bottom:40px;
    left:0px;
    width:100%;
    overflow: hidden;
    z-index: 1;

    @media(max-width: 1023px) {
        top:94px;
    }

    @media(max-width: 767px) {
        position: relative;
        top:0px;
        height:63.66vw;
    }

    @media(max-width: 480px) {
        height:62vw;
    }

    .flex-control-nav {
        right: 50px;
        position: fixed;
        top: 50%;
        width: 4px;
        bottom:auto;
        z-index: 998;

        @media(max-width: 767px) {
            position: absolute;
        }

        li {
            display: inline-block;
            margin:4px 6px;
        }
    }

    .flex-control-paging li a {
        background: #fff none repeat scroll 0 0;
        border: medium none;
        box-shadow: none;
        height: 60px;
        width: 4px;
        border-radius: 0px;

        @media(max-width: 767px) {
            height:30px;
        }

        &.flex-active {
            background-color: #638792;
        }
    }

    .flex-direction-nav {
        li {
            a {

                top:50%;
                margin-top:-15px;
                width:30px;
                height:30px;
                background: #fff;
                opacity: 1;
                font-size: 12px;
                text-align: center;
                position: fixed;

                @media(max-width: 767px) {
                    position: absolute;
                }

                &.flex-prev {
                    left:0px;

                    &:before {
                        font-family: 'skeleticons';
                        content: "\edc7";
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                    }
                }

                &.flex-next {
                    right:0px;

                    &:before {
                        font-family: 'skeleticons';
                        content: "\edc5";
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }

    ul.slides {
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;

        li {
            float:left;
            width: 100%;
            height:calc(100% - 174px);
            top:134px;
            bottom:40px;
            background-size: contain;
            background-position: center center;
            display: none;
            background-repeat: no-repeat;
            position: fixed !important;
            z-index: 1;

            @media(max-width: 1023px) {
                top:94px;
                height:calc(100% - 134px);
            }

            @media(max-width: 767px) {
                position: relative !important;
                top:0px;
                height:100%;
            }
        }
    }
}
