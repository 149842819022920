.jconfirm.jconfirm-light {
	.jconfirm-box {
		position: relative;
		padding: 30px 35px 50px;
	    background-color: $color-light;
	    line-height: 1.6;
	    box-shadow: -2px -1px 15px 3px rgba(0,0,0,0.1);
	    border-radius: 5px;
		text-align: center;
		overflow: hidden;

		.jconfirm-title {
			font-size: 20px;
			font-weight: 500;
			line-height: 24px;
		}

		.jconfirm-content {
			color: $color-body;
			font-size: 15px;
		}

		.jconfirm-buttons {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 0;

			button {
				border-radius: 0;
				width: 60%;
				float: left;
				margin: 0;
				font-weight: bold;
				font-size: 17px;
				text-transform: none;
				text-align: center;

				&:last-child {
					width: 40%;
				}

				&.btn-single {
					width: 100% !important;
				}
			}
		}

	}
}
