@mixin responsive($media, $fixed: false) {
	@if $fixed {
		@if $media == small {
			@media only screen and (max-width: $mobile - 1) { @content; }
		}
		@if $media == mobile {
			@media only screen and (min-width: $mobile) and (max-width: $tablet - 1) { @content; }
		}
		@else if $media == tablet {
			@media only screen and (min-width: $tablet) and (max-width: $desktop - 1) { @content; }
		}
		@else if $media == desktop {
			@media only screen and (min-width: $desktop) and (max-width: $large - 1) { @content; }
		}
		@else if $media == large {
			@media only screen and (min-width: $large) { @content; }
		}
	} @else {
		@if $media == small {
			@media only screen and (max-width: $small) { @content; }
		}
		@if $media == mobile {
			@media only screen and (max-width: $mobile) { @content; }
		}
		@else if $media == tablet {
			@media only screen and (max-width: $tablet) { @content; }
		}
		@else if $media == desktop {
			@media only screen and (max-width: $desktop) { @content; }
		}
		@else if $media == large {
			@media only screen and (max-width: $large) { @content; }
		}
	}
}// @include responsive(mobile, false) { float:left; };

@mixin clear() {
	&:after {
		content: '';
		display: block;
	    clear: both;
	    *zoom: 1;
	}
}// @include clear;


@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&:-ms-input-placeholder      {@content}
} // @include placeholder { color: #000; };

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transition($on: all, $duration: 300ms, $method: ease) {
	-webkit-transition: $on $duration $method;
	-moz-transition: $on $duration $method;
	-ms-transition: $on $duration $method;
	-o-transition: $on $duration $method;
	transition: $on $duration $method;
}// @include transition(all, 300ms, ease);

@mixin antialiased {
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}// @include antialiased; };

@mixin box-shadow($left:0, $top:2px, $blur:2px, $color:#000, $inset:"") {
	-webkit-box-shadow:$left $top $blur $color #{$inset};
	-moz-box-shadow:$left $top $blur $color #{$inset};
	box-shadow:$left $top $blur $color #{$inset};
}// @include box-shadow;
