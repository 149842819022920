header {

    @include transition(all,0ms,ease);

    .mobileMenu {
        position: fixed;
        top:70px;
        height:calc(100% - 70px);
        background:#12172a;
        display: none;
        opacity: 0;
        visibility: hidden;
        width: 100%;
        padding:0px 20px 0px;

        @media(max-width: 767px) {
            display: block;
        }

        &.active {
            visibility: visible;
            opacity: 1;
            overflow: auto;
        }

        ul {
            float:left;
            width: 100%;
            margin-top:50px;
            margin-bottom: 70px;

            li {
                float:left;
                width: 100%;

                &.hasChildren {

                    &.active {
                        > a {

                            color:#638792;

                            &:after {
                                content: "\ed5b";
                            }

                        }

                        ul {
                            display: block;

                            li.active a {
                                color:#638792;
                            }
                        }
                    }

                    > a {
                        position: relative;

                        &:after {
                            position: absolute;
                            right:10px;
                            top:0px;
                            font-size: 18px;
                            font-weight: normal;
                            content: "\ed5a";
                            font-family: 'skeleticons';
                        }
                    }

                }

                a {
                    color:#fff;
                    float:left;
                    width: 100%;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 1.8;

                    &:hover {
                        color:#638792;
                    }
                }

                ul {
                    display: none;
                    margin:0px;

                    a {
                        text-transform: none;
                        color:#b0b0b0;
                    }
                }
            }
        }
    }

    a.menuBtn {

        position: absolute;
        right:25px;
        top:26px;
        width:26px;
        height:18px;
        display: none;

        @media(max-width: 767px) {
            display: block;
        }

        &.active {

            span {
                background-color: transparent;

                &:before {
                    transform: translateY(10px) rotateZ(-45deg);
                    -webkit-transform: translateY(10px) rotateZ(-45deg);
                    top:-10px;
                }

                &:after {
                    transform: translateY(-10px) rotateZ(45deg);
                    -webkit-transform: translateY(-10px) rotateZ(45deg);
                    top:10px;
                }
            }
        }

        span {
            width:100%;
            height:2px;
            position: absolute;
            top:8px;
            background:#fff;
            display: block;

            @include transition(all,300ms,ease);

            &:before {
                position: absolute;
                content:'';
                background:#fff;
                width:100%;
                height:2px;
                left:0px;
                top:-8px;

                @include transition(all,300ms,ease);
            }

            &:after {
                position: absolute;
                content:'';
                background:#fff;
                width:100%;
                height:2px;
                left:0px;
                top:8px;

                @include transition(all,300ms,ease);
            }
        }
    }

    nav.main {
        float: left;
        padding-left: 502px;
        margin-top:92px;

        @media(max-width: 1024px) {
            padding-left:366px;
            margin-top:64px;
        }

        @media(max-width: 767px) {
            display: none;
        }

        > ul {
            > li {
                float: left;
                line-height: 42px;
                margin-right: 34px;
                position: relative;
                cursor: pointer;

                &.active {
                    > a {
                        color: $color-blue;
                    }
                }

                @media(max-width: 1024px) {
                    line-height: 30px;
                }

                &:hover {
                    > a {
                        color: $color-blue;
                    }
                }

                a {
                    font-family: $font-nav;
                    font-size: 13px;
                    letter-spacing: 2px;
                    font-weight: 400;
                    float:left;

                    &:hover {
                        color: $color-blue;
                    }
                }

                > a {
                    color: $color-light;
                    text-transform: uppercase;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    ul {
                        opacity: 1;
                        visibility: visible;
                        max-height: 1000px;
                    }
                }

                ul {
                    @include transition(all,300ms,ease);
                    position: relative;
                    //@include box-shadow(0, 0, 10px, #a8a8a8);
                    //box-shadow: inset 0 0 1em gold;
                    position: absolute;
                    top: 42px;
                    left: -10px;
                    width: 186px;
                    background: rgba(93,96,114, 0.85);
                    z-index: 9;
                    padding: 0px 0px 0px 0px;
                    opacity: 0;
                    visibility: hidden;
                    max-height: 0;

                    @media(max-width: 1024px) {
                        top:30px;
                    }

                    /*span.triangle {
                        &:before {
                            border-bottom: 16px solid $color-light;
                        }
                    }*/


                    li {
                        line-height: 1;
                        position: relative;

                        &.active {
                            > a {
                                /*color: $color-light;*/
                                color:#638792;
                            }
                        }

                        a {
                            font-size: 15px;
                            font-weight: 400;
                            color: $color-light;
                            line-height: 2.05;
                            word-break: break-all;
                            letter-spacing: 0;
                            float:left;
                            width: 100%;
                            padding:0px 10px;

                            &:hover {
                                color: $color-light;
                                background:#638792;
                            }
                        }

                    }
                }
            }
        }


        @include responsive(desktop) {

            > ul {
                > li {
                    margin-right: 20px;
                }
            }

    	}

        @media(max-width: 960px) {
            // nav.main {
            //     > ul {
            //         > li {
            //             margin-right: 15px;
            //         }
            //     }
            // }
        }

        @include responsive(tablet) {

        }

        @media(max-width: 1000px) {

        }

        @media(max-width: 940px) {

        }

        @include responsive(mobile) {


        }

        @include responsive(small) {

        }
    }
}
