// RESPONSIVE
$small: 380px;
$mobile: 620px;
$tablet: 768px;
$desktop: 1024px;
$large: 1280px;

// CONTAINER
$container-min: 320px;
$container-max: 1100px;
$container-padding: 0;
$container-padding-tablet: 30px;
$container-padding-mobile: 13px;

// COLORS
$color-green: #006b54;
$color-yellow: #ffd100;
$color-lightyellow: #ffd149;
$color-darkyellow: #927b11;
$color-blue: #638792;
$color-lightblue: #b8dbf6;
$color-grey: #808080;
$color-lightgrey: #d5d5d5;
$color-darkgrey: #666;
$color-red: #be0d1c;
$color-dark: #000000;
$color-light: #fff;
$color-body: #5d5d5d;
$color-bg: #f4f4f4;
$color-table: #efeeee;
$color-placeholder: rgba($color-dark, 0.5);

// FONTS
$font-base: 'Open Sans', Arial, sans-serif;
$font-main: 'Open Sans', Arial, sans-serif;
$font-nav: 'Open Sans', Arial, sans-serif;
$font-slider: 'Open Sans', Arial, sans-serif;
$font-icon: 'icons';
$font-size: 15px;
$line-height: 21px;
$font-weight: normal;
$text-align: left;

// TRANSITION
$transition: 300ms;

// TEXT SELECTION
$selection-bg: $color-red;
$selection-color: $color-light;

// OVERLAY
$overlay-bg: rgba(#1d1d1b, 0.55);
