body.home {

	&.active {
		.loading {
			opacity: 1;

			img {
				transform:scale(1) translateY(0px);
				-webkit-transform:scale(1) translateY(0px);
				opacity: 1;
			}
		}
	}

	&.loaded {
		.loading {
			opacity: 0;
			visibility: hidden;
		}
	}

	.loading {
		position: fixed;
		opacity: 1;
		top:0px;
		left:0px;
		width:100%;
		height:100%;
		background:#12172a;
		z-index: 9999;

		@include transition(all,600ms,ease);

		img {
			opacity: 0;
			transform: scale(0.95) translateY(1vw);
			-webkit-transform: scale(0.95) translateY(1vw);
			@include transition(all,2500ms,ease);

			@media(max-width: 1024px) {

                width:290px;

            }

            @media(max-width: 767px) {

                width: 200px;

            }
		}
	}

	section.hero {
		.count {
			display: none !important;
		}
	}

	section.tabs {
		.snippet {
			.sponsored {
				display: none;
			}
		}
	}

	@include responsive(desktop) {

	}

	@include responsive(mobile) {

	}
}
