//@import 'lity/dist/lity.css';
//@import 'slick-carousel/slick/slick.scss';
@import 'flexslider/flexslider.css';
@import 'magnific-popup/dist/magnific-popup.css';
@import 'assets/styles/skelet';

@import 'assets/styles/variables';
@import 'assets/styles/mixins';
@import 'assets/styles/reset';
@import 'assets/styles/fonts';
@import 'assets/styles/icons';
@import 'assets/styles/base';
@import 'assets/styles/typography';
@import 'assets/styles/forms';
@import 'assets/styles/search';
@import 'assets/styles/grid';
@import 'assets/styles/confirm';
@import 'assets/styles/print';
@import 'assets/styles/helpers';

@import "components/footer/footer.scss";
@import "components/header/header.scss";
@import "components/navigation/desktop.scss";
@import "components/navigation/sidebar.scss";
@import "components/slider/slider.scss";
@import "components/map/map.scss";
@import "components/carousel/carousel.scss";
@import "components/grid/grid.scss";
@import "components/projectslider/projectslider.scss";

@import "sections/image/image.scss";
@import "sections/slider/slider.scss";
@import "sections/title/title.scss";

@import "templates/home/home.scss";
@import "templates/media/media.scss";
@import "templates/page/page.scss";
@import "templates/people/people.scss";
@import "templates/project/project.scss";
