section.title {
	div.container {
		@include clear;
		padding-top: 35px;
		position: relative;

		h5 {
			position: absolute;
			top: -11px;
			left: 4px;
		}

		h2 {
			font-size: 47px;
			font-weight: bold;
			line-height: 1;
			margin-bottom: 10px;
			padding-bottom: 35px;
			border-bottom: 1px solid $color-darkgrey;
		}

		> div {
			position: relative;
			display: inline-block;
			width: 100%;

			p {
				font-size: 13px;
				font-weight: 600;
				display: inline-block;
				float: left;
				color: $color-dark;
				text-transform: uppercase;
				margin-right: 23px;
				margin-bottom: 0;

				span {
					color: $color-grey;
				}

				&.sponsored {
					float: right;
					margin-right: 0;
				}
			}
		}
	}

	@include responsive(desktop) {
		div.container {
			h2 {
				line-height: 1.2;
			}
		}
	}

	@include responsive(tablet) {
		div.container {
			h5 {
				left: 20px;
				//top: -45px;
			}
		}
	}

	@include responsive(tablet,true) {
		div.container {
			h5 {
				left: 20px;
			}
		}
	}

	@include responsive(mobile) {
		div.container {
			//padding-left: 13px !important;
			//padding-right: 13px;
			padding-top: 28px;

			h5 {
				//left: 13px;
				//top: -20px;
			}

			h2 {
				font-size: 24px;
				padding-right: 20px;
				padding-bottom: 20px;
				margin-bottom: 15px;

				> span {
					&:first-child {
						display: none;
					}
				}
			}

			> div {
				p {
					font-size: 12px;
				}
			}
		}
		}

	@include responsive(small) {
		div.container {
			h2 {
				font-size: 22px;
				margin-bottom: 5px;
			}

			> div {
				p {
					margin-right: 10px;
					font-size: 10px;
				}
			}
		}
	}

}


.large {
	section.title {
		div.container {
			padding-top: 85px;
			h2 {
				font-size: 72px;
				padding-bottom: 70px;
			}

			@include responsive(tablet,true) {
				h2 {
					font-size: 45px;
				}
			}

			@include responsive(tablet) {
				padding-top: 75px;
				h2 {
					font-size: 45px;
				}

				> div {
					p {
						font-size: 14px;
					}
				}

			}

			@include responsive(mobile) {
				padding-top: 28px;

				h2 {
					font-size: 24px;
					padding-bottom: 20px;
				}

				> div {
					p {
						font-size: 12px;
					}
				}

			}

			@include responsive(small) {
				padding-top: 28px;

				h2 {
					font-size: 22px;
					margin-bottom: 5px;
				}

				> div {
					p {
						margin-right: 10px;
						font-size: 10px;
					}
				}
			}
		}
	}
}
