.textCarousel {

    ul.slides {
        position: absolute;
        top:0px;
        left:0px;
        width: 100%;
        height:100%;
        padding: 0px !important;

        li {
            position: absolute;
            top:0px;
            left:0px;
            width: 100%;
            height:100%;
            display: none;

            .textBlock {
                position: absolute;
                top:0px;
                left:0px;
                width: 100%;
                height:100%;
                text-align: left;
                padding:0px 20px 0px 0px;
                font-style: italic;
                font-size: 17px;
                line-height: 1.2;
                color:#aaa;
                max-width: 800px;

                @media(max-width: 1023px) {
                    padding:0px 20px;
                    max-width: 100%;
                }

                span.title {
                    text-transform: uppercase;
                    font-size: 19px;
                }

                .v-box {
                    text-align: left;
                }

                @media(max-width: 640px) {
                    font-size: 15px;

                    span.title {
                        font-size: 17px;
                    }
                }
            }
        }
    }

}
