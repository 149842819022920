.centered {
	display: table;

	center {
		display: table-cell;
		vertical-align: middle;
	}
}

.cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.clear {
	&:after {
		content: '';
		display: block;
		clear: both;
		*zoom: 1;
	}
}

.fill {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.absolute {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.fixed {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.reset {
	position: inherit;
	top: inherit;
	bottom: inherit;
	left: inherit;
	right: inherit;
	z-index: inherit;
}

.icon {
	content: '';
	font-family: $font-icon;
}
