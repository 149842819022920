body {

	#ais-main {
		section.filter {
			    margin-bottom: 25px;
		}
	}
	.aa-dropdown-menu {
		width: 100%;
		border: none;
		color: $color-dark;
		font-family: $font-main;
		border-radius: 0;
		box-shadow: none;
		box-shadow: 0 10px 40px 2px rgba($color-dark,0.1);
		max-height: 650px;
		overflow-y: hidden;
		overflow-x: scroll;

		.autocomplete-header {
			margin: 0;
			height: 45px;
			line-height: 48px;
			border: none;
			background-color: $color-yellow;
			color: $color-light;
			padding: 0 28px;
			font-size: 14px;
			font-weight: 800;
		}
		.aa-suggestions {
			padding: 15px 0;
		}

		.aa-suggestion {
			position: relative;
		    padding: 0;

			img {
				display: none;
			}

			a {
				display: block;
				padding: 0;
				padding: 15px 28px 5px;
			}

			em {
			    color: $color-dark;
			    background: none;
			    font-weight: bold;
			}

			&.aa-cursor {
				background-color: $color-grey;
			}

			.suggestion-post-title {
			    font-size: 25px;
				font-weight: 500;
				display: block;
				width: 100%;
				margin-bottom: 8px;
			}

			.suggestion-post-content {
				font-size: 16px;
				padding-bottom: 10px;

				em {
				    padding: 0;
				    background: none;
				    box-shadow: none;
				    color: inherit;
					font-weight: bold;
				}
			}
		}

	    @include responsive(desktop) {

	    }

	    @include responsive(mobile) {
			max-height: 550px;
			overflow-x: scroll;

			.autocomplete-header {
				padding: 0 14px;
			}
			.aa-suggestions {
				padding: 10px 0 10px;
			}

			.aa-suggestion {
				a {
					display: block;
					padding: 0;
					padding: 12px 14px 5px;
				}

				.suggestion-post-title {
					font-size: 22px;
					margin-bottom: 5px;
				}

				.suggestion-post-content {
					font-size: 16px;
					padding-bottom: 8px;
					padding-top: 5px;

					em {
						padding: 0;
						background: none;
						box-shadow: none;
						color: inherit;
						font-weight: bold;
					}
				}
			}
	    }

	    @include responsive(small) {

	    }
	}
}
