.popupBlock {
    max-width: 640px;
    padding:40px 40px;
    display: inline-block;
    color:#fff;
    position: relative;
    background:rgba(99, 135, 146, 1);
    text-align: left;

    h2 {
        padding-right: 120px;
        float:left;
        width: 100%;
    }

    h3 {
        padding-right: 120px;
        font-weight: 400;
        float:left;
        width: 100%;
    }

    h4 {
        text-transform: none;
        padding-right: 120px;
        font-weight: 400;
        float:left;
        width: 100%;
    }

    img {
        position: absolute;
        top:40px;
        right:40px;
    }

    @media(max-width: 640px) {
        padding:30px 30px;

        h2 {
            margin-bottom: 25px;
        }

        h3, h4 {
            margin-bottom: 20px;
        }
    }

    @media(max-width: 320px) {
        img {
            display: none;
        }

        h2,h3,h4 {
            padding-right: 0px;
        }
    }
}

.mfp-content {
    text-align: center;
}

.mfp-close-btn-in .mfp-close {
    color:#fff !important;
}

.mfp-bg {
    background:none !important;
}
