input,
textarea,
select {
	@include placeholder {
		font-family: $font-nav;
		font-weight: bold;
		color: $color-grey;
		opacity: 1;
	}
}

input {
	@extend .input;

	&[type="checkbox"],
	&[type="radio"] {
		@extend .check;
	}
}

textarea {
	@extend .input;
	@extend .textarea;
}

select {
	@extend .input;
	@extend .select;
}

button,
input[type="button"],
input[type="submit"] {
	@extend .input;
	@extend .button;
	display: -webkit-box !important;
	transition: all $transition ease !important;

	&.outlined {
		@extend .outlined;
	}
}

.input {
	font-family: $font-nav;
	font-weight: bold;
	color: $color-dark;
	outline: 0;
	width: 100%;
	height: 32px;
	line-height: 32px;
	background: none;
	border: none;
	//border-bottom: 2px solid $color-light;
	border-radius: 0;
	padding: 0 1px;
	overflow: hidden;
	transition: all $transition ease;
	//margin-bottom: 20px;
	margin-bottom: 0;
	font-weight: 500;
	padding-left: 15px;
	background-color: $color-light;
	
	@include responsive(mobile) {
		padding-left: 8px;
	}
}

.textarea {
	height: 124px;
	padding: 15px 15px;
	line-height: 1.2;
	resize: none;
}

select.select {
	font-family: $font-nav;
	font-weight: bold;
	color: $color-dark;
	-webkit-padding-start: 1px;
	-moz-padding-start: 1px;
	background: url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.78 250"><defs><style> .cls-1 { fill: #020202; }</style></defs><title>down-arrow</title><g><polygon class="cls-1" points="192.67 131.72 114.08 210.3 114.08 0.5 93.71 0.5 93.71 210.3 15.11 131.7 0.7 146.1 103.9 249.3 207.08 146.12 192.67 131.72"/><path class="cls-1" d="M125,250L21.11,146.1,36.22,131l78.1,78.1V0h21.37V209.1L213.78,131l15.11,15.11ZM22.52,146.1L125,248.59,227.48,146.12l-13.7-13.7L134.7,211.5V1H115.31V211.5l-79.1-79.1Z" transform="translate(-21.11)"/></g></svg>');
	background-size: 11px;
	background-repeat: no-repeat;
	background-position: right 18px;
	background-color: $color-light;
	padding-left: 15px;
	height: 32px;
	line-height: 32px;
	border: none;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	@include responsive(mobile) {
		padding-left: 8px;
	}
}

select::-ms-expand { /* for IE 11 */
    display: none;
}

.check {
	width: 18px;
	height: 18px;
	background-color: $color-light;
	margin-bottom: 0;
	margin-right: 10px;
}

.button {
	font-family: $font-nav;
	display: inline-block;
	width: 285px;
	height: 32px;
	line-height: inherit !important;
	cursor: pointer;
	background: $color-red;
	border: none;
	color: $color-light;
	text-decoration: none;
	font-size: 17px;
	font-weight: bold;
	//transition: background ease $transition, border ease $transition, color ease $transition;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	text-align: center;

	//box-shadow: inset 0 0 0 0 $color-dark;
	transition: all ease $transition;

	&:hover {
		border: none;
		text-decoration: none;
		//background-color: $color-dark;
		//color: $color-red;
		//box-shadow: inset 0 100px 0 0 $color-dark;
	}

	&.secondary {
		background: $color-light;
		color: $color-dark;
		box-shadow: inset 0 0 0 0 $color-light;

		&:hover {
			box-shadow: inset 0 100px 0 0 $color-dark;
			//background-color: $color-dark;
			color: $color-light;
		}
	}

	&.tertiary {
		background: none;
		border: 2px solid $color-dark;
		color: $color-dark;
		line-height: 48px;
		box-shadow: inset 0 0 0 0 $color-dark;

		&:hover {
			border: 2px solid $color-dark;
			background-color: $color-light;
			color: $color-dark;
			box-shadow: inset 0 100px 0 0 $color-light;
		}
	}

    @include responsive(tablet) {
		//font-size: 15px;
		//height: 46px;
    }
}

input.search {
	background: url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 242.45"><defs><style> path { fill: #ffffff; }</style></defs><title>search</title><path d="M218.67,35A106.91,106.91,0,0,0,56.58,173.45L0,230l16.18,16.18L72,190.42A106.89,106.89,0,0,0,218.67,35ZM202.5,170a84,84,0,1,1,24.61-59.42A84.12,84.12,0,0,1,202.5,170Z" transform="translate(0 -3.77)"/></svg>');
	background-size: 15px;
	background-repeat: no-repeat;
	background-position: center;
	background-color: $color-light;
}

.outlined {
	background: none;
	border: 2px solid $color-dark;
	color: $color-dark;

	&:hover {
		background-color: $color-dark;
		border: 2px solid $color-dark;
		color: $color-light;
	}
}

form {

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			&.gfield_error {
				ul.gfield_checkbox,
				ul.gfield_radio {
					li {
						input:checked {
							+ label {
								color: $color-dark !important;

								&:before {
									background-color: $color-light !important;
								}
							}
						}
						label {
							color: $color-red;

							&:before {
								background-color: $color-red;
							}
						}
					}
				}
			}

			&.gf_left_half {
				float: left;
				width: calc(50% - 12px);
				margin-right: 12px;
			}

			&.gf_right_half {
				float: right;
				width: calc(50% - 12px);
				margin-left: 12px;
			}
		}
	}

	&.animated {
		text-align: center;

		ul {

			li.transition,
			span.transition {
				display: block;
				position: relative;
				overflow: hidden;
				padding-top: 20px;
				margin-bottom: 15px;
				min-height: 74px;

				.input {
					height: 54px;
					width: 100%;
					position: absolute;
					left: 0;
					right: 0;
					border: none;
					margin-bottom: 0;
					background: none;
					color: rgba($color-dark, 0.9);
					z-index: 2;
					padding: 4px 15px 0;
					color: rgba($color-dark, 0.8);
				}

				&.textarea {
					min-height: 140px;

					.input {
						height: 120px;
						padding: 10px 15px 0;
					}

					label {
						height: 120px !important;
					}
				}

				&.select {
					background-position: right 40px;
				}

				label:not([for="input_2_18_2"]) {
					width: 100%;
					position: absolute;
					left: 0;
					right: 0;

					height: 54px;
					text-align: left;
					pointer-events: none;
					user-select: none;
					font-weight: bold;
					color: rgba($color-dark, 0.8);

					&:before,
					&:after {
						content: '';
						position: absolute;
						width: 100%;
						left: 0;
					}

					&:before {
						height: 100%;
						background-color: $color-light;
						top: 100%;
						transition: transform 0.2s;
						//border-radius: 3px 3px 0 0;
						border-radius: 0;
					}

					&:after {
						height: 2px;
						background-color: $color-light;
						top: calc(100% - 2px);
						transition: opacity 0.2s;
					}

					span {
						opacity: 0.6;
						display: inline-block;
						line-height: 54px;
						padding: 0;
						transform-origin: 0 0;
						transition: transform 0.2s, color 0.2s;
					}
				}

				&.filled .input,
				.input:focus {
					opacity: 1;
					transition: opacity 0s 0.2s;
				}

				label:before,
				label:after,
				label span,
				.input:focus,
				&.filled .input {
					transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
				}

				.input:focus + label:before,
				&.filled label:before {
					transform: translate3d(0, -100%, 0);
				}

				.input:focus + label:after,
				&.filled label:after {
					opacity: 0;
				}

				.input:focus + label span,
				&.filled label span {
					opacity: 1;
					transform: translate3d(0, -32px, 0) scale3d(0.8, 0.8, 1);
				}

				&.gfield_error {
					label {
						color: $color-red;

						&:after {
							background-color: $color-red;
						}
					}

					ul.gfield_checkbox,
					ul.gfield_radio {
						li {
							label {
								&:before {
									background-color: $color-red;
								}
							}
						}
					}
				}
			}
		}

		input[type=submit] {
			margin-top: 10px;
		}
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]),
	select,
	textarea {
		width: 100%;
	}

	input[type=submit] {
		margin: 0 auto;
	}

	ul.gfield_checkbox,
	ul.gfield_radio {
		text-align: center;

		li {
			display: inline-block;
			position: relative;
			padding-top: 20px;
			margin-bottom: 20px;

			input {
				opacity: 0;
				pointer-events: none;

				&:checked + label {
					&:after {
						opacity: 1;
						transform: scale(1);
					}
				}
			}

			label {
				display: inline-block;
				position: relative;
				white-space: nowrap;
				cursor: pointer;
				margin: 0 20px;
				padding: 5px 0;
				user-select: none;
				transition: all ease $transition;

				&:before {
					content: '';
					position: absolute;
					height: 18px;
					width: 18px;
					top: 3px;
					left: -28px;
					background-color: $color-light;
					transition: all ease $transition;
					z-index: 1;
				}

				&:after {
					content: '\E01C';
					position: absolute;
					height: 18px;
					width: 18px;
					top: 5px;
					left: -25px;
					font-family: $font-icon;
					font-size: 12px;
					z-index: 2;
					opacity: 0;
					transition: all ease $transition;
					transform: scale(0.5);
				}
			}
		}
	}

	.validation_error,
	.gfield_creditcard_warning_message,
	.gform_confirmation_message {
		line-height: 1.5;
		padding: 10px 20px 20px;
	}

	.validation_error,
	.gfield_creditcard_warning_message {
		font-weight: bold;
	}

	.validation_message,
	.gfield_required,
	.gform_title {
		display: none;
	}

	img.gform_ajax_spinner {
	    display: none !important;
	}



    @include responsive(mobile) {

		ul {
			li {
				&.gf_left_half {
					float: none;
					width: 100%;
					margin-right: 0;
				}

				&.gf_right_half {
					float: none;
					width: 100%;
					margin-left: 0;
				}
			}
		}

		&.animated {
			ul  {
				li.transition,
				span.transition  {
					margin-bottom: 15px;
					min-height: 58px;

					label:not([for="input_2_18_2"]) {
						height: 38px;

						span {
							line-height: 38px;
						}
					}

					.input,
					input {
						height: 38px;
					}

					select {
						padding: 1px 10px  !important;
						background: none !important;
					}

					.input:focus + label span,
					&.filled label span {
						transform: translate3d(0, -24px, 0) scale3d(0.8, 0.8, 1);
					}
				}
			}
		}

		.validation_error,
		.gfield_creditcard_warning_message {
			font-size: 15px;
		}

		ul.gfield_checkbox,
		ul.gfield_radio {
			li {

				label {
					white-space: normal;
				}
			}
		}
	}
}

.gform_confirmation_message {
	//font-weight: bold;
	line-height: 1.5;
	padding: 10px 20px 20px;
}

.gfield_visibility_hidden {
	display: none !important;
}

div.tipso_bubble {
	background-color: #fff;
	line-height: 1.2;
	box-shadow: -1px -1px 20px 3px rgba(0,0,0,0.2);
	background-clip: padding-box;
	color: #000;
	font-family: "Avenir";
	font-size: 14px;
	border-radius: 5px;
	text-align: left;
	padding: 15px 20px;
	width: 350px;
	line-height: 1.6;
	font-size: 16px;
}
