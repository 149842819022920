.gridBlock {
    float:left;
    width: 100%;
    opacity:0;
    position: relative;
    padding-bottom: 40px;
    overflow: hidden;

    @include transition(all,1000ms,ease);

    .grid-sizer {
        width:24.5%;

        @media(max-width: 1279px) {
            width:33%;
        }

        @media(max-width: 1023px) {
            width:24.5%;
        }

        @media(max-width: 768px) {
            width:33%;
        }

        @media(max-width: 640px) {
            width:49.5%;
        }

    }

    .gutter-sizer {
        width: 0.66%;

        @media(max-width: 1279px) {
            width:0.5%;
        }

        @media(max-width: 1023px) {
            width:0.66%;
        }

        @media(max-width: 768px) {
            width:0.5%;
        }

        @media(max-width: 640px) {
            width:1%;
        }
    }

    &.active {
        visibility: visible;
        opacity: 1;
    }

    .element {
        float:left;
        position: relative;
        margin-bottom: 0px;
        width:24.5%;
        overflow: hidden;

        @media(max-width: 1279px) {
            width:33%;
        }

        @media(max-width: 1023px) {
            width:24.5%;
        }

        @media(max-width: 768px) {
            width:33%;
        }

        @media(max-width: 640px) {
            width:49.5%;
        }

        img {
            float:left;
            max-width: 100%;
            transform: scale(1);
            -webkit-transform: scale(1);

            @include transition(all,500ms,ease);
        }

        span.overlay {
            position: absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;
            opacity: 0;
            background:rgba(99,135,146,0.8);
            text-align: center;
            text-transform: uppercase;
            padding:10px;
            color:#fff;

            span.name {
                float:left;
                width: 100%;
                font-weight: 600;
            }

            span.jobTitle {
                float:left;
                width: 100%;
                text-transform: none;
                margin:5px 0px;
            }

            span.cred {
                float:left;
                width: 100%;
                text-transform: none;
                font-size: 12px;
            }

            @include transition(all,500ms,ease);
        }

        &:hover {

            img {
                transform: scale(1.1);
                -webkit-transform: scale(1.1);
            }

            span.overlay {
                opacity: 1;
            }
        }
    }
}
