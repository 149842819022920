/*--------------------------------------------
	NeutronCSS
	http://neutroncss.com/docs/

	$columns: ""			integer|list
	$container-width: 960px	[CSS width]
	$container-align: ""	left|right|center
	$margin: ""				[CSS margin]
	$flush-margin: true		true|false
	$flush-padding: false	false|true
	$target: "*"			[CSS selector]
	$order: ""				integer|list
--------------------------------------------*/

// Settings Management
// ============================================

$_neutron: (
	layout: (
		column-padding: 0,
		container-max-width: $container-max,
		flush-margin: true,
		flush-padding: false
	),
	query: (
		mobile-max: 		$small - 1,
		phablet-max: 		$mobile - 1,
		tablet-max: 		$tablet - 1,
		desktop-sml-max:	$desktop - 1,
		desktop-mid-max:	$large - 1
	)
) !default;

@function setting($map_name: "", $setting: "") {
	@if $map_name != "" and $setting != "" {
		$map: map-get($_neutron,$map_name);
		@return map-get($map, $setting);
	}
}

// Floatbox Grid
//---------------------------------------------

//Declares the container element of child rows or columns
@mixin columns(
	$columns:"",
	$container-width: setting("layout","container-max-width"),
	$container-align: "",
	$margin: "",
	$flush-margin: setting("layout","flush-margin"),
	$flush-padding: setting("layout","flush-padding"),
	$target: "*",
	$order: ""
) {
	@include container-width($container-width);
	margin-left: auto;
	margin-right: auto;

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	@if $columns != "" {

		$columns: neutron_calc-column-ratio($columns);
		$column-sum: neutron_sum($columns);
		$column-count: length($columns);
		$column-widths: neutron_calculate-column-widths($columns);
		$target-child-selector: neutron_child-selector($target);

		$margin-left: 0;
		$margin-right: 0;

		@if $margin != "" {

			//get margins for left and right of the columns
			$margin-left: neutron_extract-position($margin,left);
			$margin-right: neutron_extract-position($margin,right);

		}

		//assign calculated widths to elements
		$index: 0;
		@each $width in $column-widths {
			$index: $index + 1;

			& > #{$target}:#{$target-child-selector}(#{$column-count}n+#{$index}) {

				$calc-contents: "#{$width}";

				@if $margin != "" {

					@if $margin-left != 0 {
						$calc-contents: $calc-contents + " - #{$margin-left}";
					}

					@if $margin-right != 0 {
						$calc-contents: $calc-contents + " - #{$margin-right}";
					}

					@if $flush-margin and $margin != "" {
						$flush-left: $margin-left / $column-count;
						$flush-right: $margin-right / $column-count;
						$calc-contents: $calc-contents + " + #{$flush-left}";
						$calc-contents: $calc-contents + " + #{$flush-right}";
					}

				}

				width: calc(#{$calc-contents});
				float:left;

				@content;

				@if $margin != "" {
					margin: $margin;
				}

				//if first column
				@if $index == 1 {
					clear: left;

					@if $flush-padding {
						padding-left: 0;
					}
					@if $flush-margin {
						margin-left: 0;
					}
				} @else {
					clear: none;
				}

				//if last column
				@if $index == $column-count {
					@if $flush-padding {
						padding-right: 0;
					}
					@if $flush-margin {
						margin-right: 0;
					}
				}
			}
		}
	}

	//Set alignment of container
	@if $container-align != "" {
		@include container-align($container-align);
	}

	// Change order of columns
	@if type-of($order) == list and type-of($columns) == list {
		@include order($order, $columns, $margin, $flush-margin, $target);
	}

}

//Alias for columns()
@mixin column($arguments...) {
	@include columns($arguments...) {
		@content;
	};
}

@mixin col($arguments...) {
	@include columns($arguments...) {
		@content;
	};
}

@mixin float-columns($arguments...) {
	@include columns($arguments...) {
		@content;
	};
}

@mixin float-column($arguments...) {
	@include columns($arguments...) {
		@content;
	};
}

@mixin float-col($arguments...) {
	@include columns($arguments...) {
		@content;
	};
}

@mixin order($order: "", $columns:"", $margin:"", $flush-margin:setting("layout","flush-margin"), $target:"*") {

	@if type-of($order) == list {

		//if no ratio is set, use $order to determine number of equal width columns
		@if $columns == "" {
			$columns: length($order);
		}

		//if column ratio is not a list, generate it
		$columns: neutron_calc-column-ratio($columns);
		$column-sum: neutron_sum($columns);
		$column-count: length($columns);
		$target-child-selector: neutron_child-selector($target);

		$margin-left: 0;
		$margin-right: 0;

		@if $margin != "" {
			$margin-left: neutron_extract-position($margin,left);
			$margin-right: neutron_extract-position($margin,right);
		}

		//calculate width of each column
		$column-width-string:();

		$i: 0;
		$columns-offset: ();
		@each $column in $columns {
			$i: $i + 1;

			// calculate column widths
			$col-width: (100% / $column-sum) * $column;

			@if $margin != "" {
				@if $flush-margin {
					$flush-left: $margin-left / $column-count;
					$flush-right: $margin-right / $column-count;
					$flush: $flush-left + $flush-right;
					$col-width: $col-width + " + #{$flush}";
				}
			}

			$column-width-string: append($column-width-string, "(#{$col-width})", comma);

			// get sum of left offset of columns that come before current original position
			$current-index: 0;
			$current-offset: "0px";

			@while $current-index < $i {
				$current-index: $current-index + 1;

				$add-offset: nth($column-width-string, $current-index);
				$current-offset: $current-offset + " + " + $add-offset;
			}

			$current-offset: "(" + $current-offset + ")";
			$columns-offset: append($columns-offset, $current-offset, comma)

		}

		$new-column-width-string: neutron_reorder-list($column-width-string, $order);
		$offset-totals: ();

		//iterate over each column
		$i: 0;
		@each $column in $order {
			$i: $i + 1;

			// get sum of widths of columns that come before current one and add to offset
			$new-position: index($order, $column);
			$new-offset: "0px";

			$index: 0;
			@while $index < $new-position {
				$index: $index + 1;

				$new-offset: $new-offset + " + " + nth($new-column-width-string, $index);
			}

			//Get left offset required for this column to reset column to left side.
			$ori-offset: nth($columns-offset, $column);

			& > #{$target}:#{$target-child-selector}(#{$column-count}n+#{$column}) {
				left: calc(0px - (#{$ori-offset}) + (#{$new-offset}));
				position: relative;
			}
		}
	}
}

@mixin container-align($align:"") {
	//Set alignment
	@if $align != "" {
		float:none;

		@if $align == left {
			margin-left: 0;
		}

		@if $align == right {
			margin-right: 0;
		}

		@if $align == center {
			margin-right: auto;
			margin-left: auto;
		}
	}
}

@mixin container-width($container-width: setting("layout","container-max-width")) {
	max-width: $container-width;
}

/// Media Queries
//===============================================


// Viewport Settings
//---------------------------------------------

$mobile-max: 		setting("query", "mobile-max");
$phablet-max: 		setting("query", "phablet-max");
$tablet-max: 		setting("query", "tablet-max");
$desktop-sml-max: 	setting("query", "desktop-sml-max");
$desktop-mid-max: 	setting("query", "desktop-mid-max");

$phablet-min: 		$mobile-max + 1;
$tablet-min: 		$phablet-max + 1;
$desktop-sml-min: 	$tablet-max + 1;
$desktop-mid-min: 	$desktop-sml-max + 1;
$desktop-lrg-min: 	$desktop-mid-max + 1;

@function createBreakpoint($fromThisSize: "", $uptoThisSize: ""){
	$query: "";

	@if $fromThisSize != "" or $uptoThisSize != "" {

		@if $fromThisSize != "" and $fromThisSize != 0  {
			$query: $query + "(min-width:#{$fromThisSize})";
		}

		@if  ($fromThisSize != "" and $fromThisSize != 0) and $uptoThisSize != "" {
			$query: $query + " and ";
		}

		@if $uptoThisSize != "" {
			$query: $query + "(max-width:#{$uptoThisSize})";
		}

		@return unquote($query);
	}
}

// Media Queries
//---------------------------------------------

// Map of set breakpoints
$neutron_breakpoints: (
	mobile: 		createBreakpoint(0, $mobile-max),
	phablet: 		createBreakpoint($phablet-min, $phablet-max),
	tablet: 		createBreakpoint($tablet-min, $tablet-max),
	desktop: 		createBreakpoint($desktop-sml-min),
	desktop-sml: 		createBreakpoint($desktop-sml-min, $desktop-sml-max),
	desktop-mid: 		createBreakpoint($desktop-mid-min, $desktop-mid-max),
	desktop-lrg: 		createBreakpoint($desktop-lrg-min),

	to-phablet: 		createBreakpoint(0, $phablet-max),
	to-tablet: 		createBreakpoint(0, $tablet-max),
	to-desktop-sml:		createBreakpoint(0, $desktop-sml-max),
	to-desktop-mid: 	createBreakpoint(0, $desktop-mid-max),

	from-phablet: 		createBreakpoint($phablet-min),
	from-tablet: 		createBreakpoint($tablet-min),
	from-desktop: 		createBreakpoint($desktop-sml-min),
	from-desktop-sml: 	createBreakpoint($desktop-sml-min),
	from-desktop-mid: 	createBreakpoint($desktop-mid-min),
	from-desktop-lrg: 	createBreakpoint($desktop-lrg-min)
);

@mixin breakpoint($breakpoint: "", $resolution: "", $media-type: "", $media-feature: "") {
	@if $breakpoint != "" or $resolution != "" or $media-feature != "" or $media-type {

		$media: "";

		// Add breakpoint to media string
		@if $breakpoint != "" {
			@if map-has-key($neutron_breakpoints, $breakpoint) {
				$media: inspect(map-get($neutron_breakpoints, $breakpoint));
			}
		}

		// Add dpi to media string
		@if $resolution != "" {
			$res-dpi: $resolution * 96;

			@if $media != "" {
				$media: $media + " and";
			}

			$media: $media + " all and (min-resolution: #{$res-dpi}dpi) ";
		}

		// Add additional supplied query types to media string
		@if $media-type != "" {
			@if $media != "" {
				$media: $media + " and ";
			}

			$media: $media + $media-type;
		}

		// Add additional supplied query features to media string
		@if $media-feature != "" {
			@if $media != "" {
				$media: $media + " and ";
			}

			$media: $media + "all and (" + $media-feature + ")";
		}

		@media #{$media} {
			@content;
		}
	}
}

// Utilities
//---------------------------------------------

//hides the element when the
//provided media query is supplied.
@mixin hide($breakpoint: null) {
	@if $breakpoint {
		@include breakpoint($breakpoint) {
			& {
				display: none;
			}
		}
	} @else {
		display: none;
	}
}

//shows the element when the
//provided media query is supplied.
@mixin show($breakpoint: null) {
	@if $breakpoint {
		@include breakpoint($breakpoint) {
			& {
				display: block;
				display: initial;
			}
		}
	} @else {
		display: block;
		display: initial;
	}

}

// UTILITY FUNCTIONS
@function neutron_reorder-list($list, $order) {

	$new-list:();

	@each $index in $order {
		$item: nth($list, $index);
		$new-list: append($new-list, $item, comma);
	}

	@return $new-list;
}

@function neutron_calculate-column-widths($column-ratio) {

	$column-sum: neutron_sum($column-ratio);
	$column-count: length($column-ratio);
	$column-widths: ();

	//Determine width of each column
	@each $column in $column-ratio {
		$width: (100% / $column-sum) * $column;
		$column-widths: append($column-widths, $width, comma);
	}

	@return $column-widths;
}

@function neutron_calc-column-ratio($columns) {
	$column-ratio: $columns;

	@if type-of($columns) != list {
		$column-ratio: ();

		@for $i from 1 through $columns {
			$column-ratio: append($column-ratio, 1, comma);
		}
	}

	@return $column-ratio;
}

@function neutron_child-selector($target-selector) {
	$child-selector: "nth-of-type";

	@if $target-selector == "*" {
		$child-selector: "nth-child";
	}

	@return $child-selector;
}

// Helper Functions
// ============================================

//Adds all items in a list and returns the result
@function neutron_sum($list) {

	$total: 0;
	@each $element in $list {
		$total: $total + $element;
	}
	@return $total;

}

@function neutron_extract-position($shorthand, $position) {
	$shorthand-length: length($shorthand);

	//if only one variable passed, return it
	@if $shorthand-length == 1 {
		@return $shorthand;
	}

	@if $shorthand-length == 2 {
		@if $position == top or $position == bottom {
			@return nth($shorthand, 1);
		}

		@if $position == left or $position == right {
			@return nth($shorthand, 2);
		}
	}

	@if $shorthand-length == 3 {
		@if $position == top {
			@return nth($shorthand, 1);
		}

		@if $position == left or $position == right {
			@return nth($shorthand, 2);
		}

		@if $position == bottom {
			@return nth($shorthand, 3);
		}
	}

	@if $shorthand-length == 4 {
		@if $position == top {
			@return nth($shorthand, 1);
		}

		@if $position == right {
			@return nth($shorthand, 2);
		}

		@if $position == bottom {
			@return nth($shorthand, 3);
		}

		@if $position == left {
			@return nth($shorthand, 4);
		}
	}
}


/*--------- End of NeutronCSS ---------*/
