* {
	margin: 0;
	padding: 0;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent;

	@include antialiased;
}

html {
	box-sizing: border-box;

	& * {
		box-sizing: inherit;
	}
}

// *,
// *:after,
// *:before {
// 	box-sizing: border-box;
// }

*:focus {
	outline-color: transparent;
	outline-style: none;
}

::selection {
	background: $selection-bg;
	color: $selection-color;
	text-shadow: none;
}

::-webkit-selection {
	background: $selection-bg;
	color: $selection-color;
	text-shadow: none;
}

::-moz-selection {
	background: $selection-bg;
	color: $selection-color;
	text-shadow: none;
}

input,
textarea,
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	&:focus {
		outline: 0;
		background-color: none !important;
	}
}

input[type="button"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	padding: 0;
	border: 0;
}



input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

select::-ms-expand {
	display: none;
}

select:focus::-ms-value,
select::-ms-value {
  background: none;
  color: $color-body;
}

::-ms-clear {
   display: none;
}

fieldset {
	border: none;

	legend {
		display: none;
	}
}

header,
footer {
	ul {
		list-style: none;
	}
}

a[href^="https://maps.google.com/maps"],
a[href^="http://maps.google.com/maps"]{display:none !important}

.gm-style-cc {
    display:none;
}
.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}

.gm-style-iw {
	padding: 10px;
}
