html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
button,
input,
select,
textarea,
label {
	font-family: $font-main;
	font-size: $font-size;
	font-weight: $font-weight;
	color: $color-light;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 25px;
	color: $color-light;
}

h1 {
	font-size: 25px;
	font-weight: normal;
	margin-bottom: 30px;
	text-transform: uppercase;
	position: relative;
	letter-spacing: 2px;

	&:after {
		position: absolute;
		bottom:-5px;
		left:0px;
		height:1px;
		width:80px;
		background:#fff;
		content:'';
	}

    @include responsive(tablet) {
		/*font-size: 33px;*/
    }

    @include responsive(mobile) {
		/*font-size: 32px;*/
		font-size: 20px;

		&:after {
			width:60px;
		}
    }
}

h2 {
	font-size: 21px;
	margin-bottom: 30px;
	position: relative;
	letter-spacing: 2px;

	&:after {
		position: absolute;
		bottom:-5px;
		left:0px;
		height:1px;
		width:80px;
		background:#fff;
		content:'';
	}

    @include responsive(tablet) {
		//font-size: 23px;
    }

    @include responsive(mobile) {
		font-size: 18px;

		&:after {
			width:60px;
		}
    }
}

h3 {
	font-size: 21px;
	font-weight: 600;
	margin-bottom: 10px;
	line-height: 1;
	margin-bottom: 25px;

    @include responsive(mobile) {
		font-size: 19px;
    }
}

h4 {
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 25px;

	a {
		color: $color-blue;
		text-decoration: underline;
	}

    @include responsive(mobile) {
		font-size: 16px;
    }
}

h5 {
	&.red-type {
		font-family: $font-nav;
		font-size: 17px;
		font-weight: bold;
		color: $color-light;
		text-transform: uppercase;
		background-color: $color-red;
		display: inline-block;
		padding: 4px 8px;
		line-height: 1;

		@include responsive(mobile) {
			font-size: 11px;
		}
	}
}

p {
	font-size: 15px;
	font-weight: 400;
	line-height: 21px;
	margin-bottom: 20px;
	color: $color-light;

	&.caption {
		font-size: 12px;
		font-weight: 500;
		padding-top: 5px;
		color: #808080;
	}

	strong {
		font-weight: bold;
	}

	small {
		font-size: 14px;
	}

	em {
		font-style: italic;
	}

	a {
		color: $color-blue;
		text-decoration: underline;
		font-weight: normal;

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

    @include responsive(tablet) {
		font-size: 15px;

		small {
			font-size: 12px;
		}
    }

    @include responsive(mobile) {
		font-size: 15px;
		line-height: 1.3;

		small {
			font-size: 12px;
		}
    }
}

a {
	color: $color-dark;
	text-decoration: none;
	transition: color $transition ease;

	&:hover,
	&.active {
		color: $color-dark;
	}

	&.button {
		background-color: $color-darkgrey;
		color: $color-light;
		display: block;
		line-height: 30px !important;
		&:hover {
			background-color: $color-red;
		}
	}
}

blockquote {
	background-color: #f3f3f3;
	padding: 22px 27px;
	margin-bottom: 25px;

	p {
		margin: 0;
		color: $color-dark;
		font-weight: normal;

		strong {
			font-weight: 500;
		}
	}
}

.slider:not(.side) { display: none; }
.slider.slick-initialized { display: block; }
