section.slider {
    div.container {
        @include clear;
        padding-top: 45px;
        padding-bottom: 35px;

        div.slick-slider {
            height: 735px;
            width: 100%;


            p.caption {
                position: absolute;
                bottom: -20px;
                left: 0;
                z-index: 9;
                margin-bottom: 0;
            }

            div.slick-list {
                //display: none;
                height: 100%;
                position: relative;
                padding-bottom: 25px;

                > div {
                    height: 100%;
                    position: relative;
                }

                div.image {
                    width: 100%;
                    height: 100%;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }

                div.content {
                    position: absolute;
                    display: table-cell;
                    z-index: 11;

                    &.right {
                        right: 18px;

                        p , h2 {
                            text-align: right;
                        }
                    }

                    &.left {
                        left: 18px;

                        p , h2 {
                            text-align: left;
                        }
                    }

                    &.top {
                        vertical-align: top;
                        top: 18px;
                    }

                    &.bottom {
                        vertical-align: bottom;
                        bottom: 18px;
                    }

                    p {
                        font-family: $font-nav;
                        font-weight: bold;
                        font-size: 33px;
                        color: $color-light;
                    }

                    h2 {
                        font-family: $font-slider;
                        color: $color-light;
                    }
                }

                > a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 10;
                }

                &:first-child {
                    display: table;
                    width: 100%;
                    height: 100%;
                }
            }
        }


        @include responsive(tablet) {
            div.slick-slider {
                height: 450px;
            }
            div.slider {
                width: 100%;
            }
        }

        @include responsive(mobile) {
            padding-top: 0;
            padding-bottom: 20px;

            div.slick-slider {
                width: 100%;
                height: 250px;
                div.slick-list {

                }
            }

            a.slick-arrow {
                top: 36%;
            }
        }
    }
}


main.small {
    section.slider {
        width: calc(100% + 135px) !important;
        margin-left: -135px;

        div.container {
            div.slick-slider {
                height: 480px;
                width: 100%;
            }
        }
    }
}







// flexible

div.flexible {
	.slider {
		width: 100%;
		height: 480px;
        padding-left: 0 !important;
        //border: 1px solid #000;

        div.container {
            padding-top: 0;
            width: 100%;
            height: 100%;
        }

		.slides ,
		.image {
			width: 100%;
			height: 100%;
		}

		.slides {
			display: none;

			&:first-child {
				display: block;
			}
		}
	}

	.medium {
		.slider {
			height: 735px;
		}

		.left ,
		.right {
			padding-top: 0;
		}
	}

    @include responsive(tablet) {
        .medium {
    		.slider {
    			height: 635px;
    		}
    	}
    }

	@include responsive(mobile) {
		.slider {
			max-width: 100%;
			height: 208px;
			padding-top: 16px;
		}

        .medium {
    		.slider {
    			height: 208px;
    		}
    	}
	}
}
