* {
	margin: 0;
	padding: 0;
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.v-wrap{
    height: 100%;
    white-space: nowrap;
    text-align: center;
}
.v-wrap:before{
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    margin-right: -.25em;
    height: 100%;
}
.v-box{
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
}

html {
	font-family: $font-base;
	overflow: auto !important;
}

body,
html {
	font-family: $font-base;
	width: 100%;
	min-height: 100%;
	height:100%;
	background:#595959;
	//overflow-x: hidden;
}

body {
	text-align: $text-align;
	position: relative;
	/*overflow-x: hidden;*/

	div.container {
		//max-width: $container-max;
		margin: 0 auto;
		//padding: 0 $container-padding;

		@media (max-width: 1200px) {
			padding: 0 $container-padding-mobile;
		}

		@include responsive(desktop) {
			padding: 0 $container-padding-mobile;
			max-width: inherit;
			min-width: 320px;
			width: 100%;
		}
	    @include responsive(tablet) {
			padding: 0 $container-padding-mobile;
	    }

	    @include responsive(mobile) {
			padding: 0 $container-padding-mobile;
	    }
	}
}

body {
    animation: fadeInTransition 300ms;
}

ul {
	list-style: none;
}
ol {

	font-family: $font-main;
	li {
		padding-left: 20px;
	}
}
@keyframe fadeInTransition {
    from {opacity: 0;}
    to {opacity: 1;}
}

@-webkit-keyframes fadeInTransition {
    from {opacity: 0;}
    to {opacity: 1;}
}

main {
	overflow-x: hidden;
	min-height: 100%;
	margin-bottom: -40px;
	padding-top:134px;
	background:#595959;
	float:left;
	width: 100%;

	&.home {
		background:#12172a;
	}

	@media(max-width: 1024px) {
        padding-top:94px;
    }

    @media(max-width: 767px) {
        padding-top:70px;
    }

}

article,
footer,
header,
section {
	position: relative;
	margin: 0 auto;
	//width: 100%;
}

img {
	display: block;
	vertical-align: middle;
	max-width: 100%;
	border: none;
}

a {
	border: none;
	cursor: pointer;
	text-decoration: none;

	&:focus,
	&:hover,
	&:active {
		outline: 0;
	}
}

[class*="icon-"] {
	&:before {
		font-family: $font-icon;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-decoration: none;
		text-transform: none;
	}

	span {
		display: none;
	}
}

iframe {
	max-width: 100%;
}

div.image {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.red {
	color: $color-red;
}

span.triangle {
	&:before {
		content: ' ';
		position: absolute;
		top: -10px;
		left: 28px;
		width: 0;
		height: 0;
		border-left: 16px solid transparent;
		border-right: 16px solid transparent;
	}

}

section.two-column ,
section.full-width {
    @include clear;
    max-width: $container-max;
    margin: 0 auto;
    padding-top: 0;

	@media(max-width: 1200px) {
		padding: 0 20px;
	}

	@include responsive(tablet) {
		padding: 0;
	}

	@include responsive(tablet,true) {
		padding: 0;
	}
}


.left {
    width: calc((100% * 2 / 3 ) - 25px );
    margin-right: 25px;
    float: left;

	&.options > section:not(.hero) {
		padding-left: 135px;
	}

	@include responsive(desktop) {
		// width: 100%;
		// margin-right: 0;

		&.options > section:not(.hero) {
			padding-left: 95px;
		}
	}

	@include responsive(tablet) {
		width: 100%;
		margin-right: 0;
		float: none;

		&.options > section:not(.hero) {
			padding-left: 0;
		}
	}

	@include responsive(tablet,true) {
		width: 100%;
		margin-right: 0;
		float: none;

		&.options > section:not(.hero) {
			padding-left: 0;
		}
	}
}

.right {
    padding-top: 45px;
    width: calc((100% * 1 / 3 ) - 25px );
    margin-left: 25px;
    float: left;

	@include responsive(mobile) {
		width: 100%;
		margin-left: 0;
		float: none;
	}
}

div.sidebar {
	padding-top: 46px;
}

a.slick-arrow {
	position: absolute;
	width: 27px;
	height: 34px;
	//background-color: #a0a2a3;
	background: rgba(160,162,163,0.5);
	top: 49%;
	z-index: 99;


	&:after {
		font-family: $font-icon;
		font-size: 20px;
		position: absolute;
		left: 0;
		right: 0;
		text-align: center;
		line-height: 34px;
	}

	&.prev {
		left: 0;
		&:after {
			content: '\E00D';
		}

	}
	&.next {
		right: 0;
		&:after {
			content: '\E013';
		}
	}

	@include responsive(mobile) {
		top: 45%;
		width: 34px;
		height: 30px;

		&:after {
			font-size: 18px;
			line-height: 30px;
		}
	}
}
