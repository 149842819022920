aside {
    position: fixed;
    z-index: 99999;
    left: -322px;
    top: 0;
    bottom: 0;
    width: 322px;
    height: 100%;
    background-color: $color-light;
    padding-top: 22px;
    padding-left: 30px;
    padding-right: 34px;
    @include transition(all,600ms,ease);

    &.active {
        left: 0;
        overflow: scroll;
    }

    > a.icon-cross {
        display: block;
        line-height: 1;
        margin-bottom: 20px;
    }

    > div {
        display: inline-block;
        clear: both;

        &.links {
            display: block;

            > a {
                @include transition(all,600ms,ease);
                display: block;
                float: left;
                text-align: center;
                width: 77px;
                height: 33px;
                line-height: 33px;
                background-color: $color-darkgrey;
                color: $color-light;
                font-size: 17px;
                font-family: $font-nav;
                margin-right: 10px;
                font-weight: bold;

                &:hover {
                    background-color: $color-dark;
                }
            }
        }

        &.side-nav {
            padding-top: 16px;
            width: 100%;

            nav {
                padding-left: 0;
                width: 100%;

                a {
                    font-family: $font-nav;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1;
                }

                > ul {
                    width: 100%;
                    list-style: none;

                    > li {
                        margin-bottom: 44px;

                        > a {
                            color: $color-dark;
                            display: block;
                            padding-bottom: 7px;
                            margin-bottom: 8px;
                            border-bottom: 1px solid #e9e9e9;
                        }
                        > ul {
                            width: 100%;
                            list-style: none;

                            > span {
                                display: none;
                            }

                            li {
                                margin-bottom: 1px;
                                a {
                                    font-weight: 500;
                                    color: $color-darkgrey;

                                    &:hover {
                                        color: $color-red;
                                    }
                                }

                                &.active {
                                    a {
                                        color: $color-red;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @include responsive(desktop) {

	}

    @include responsive(tablet) {

    }

    @include responsive(mobile) {
        padding: 0;


        > a.icon-cross {
            height: 40px;
            background-color: #eaeaea;
            font-size: 20px;
            padding-left: 15px;
            font-weight: bold;

            &:before {
                content: "\E002";
                line-height: 40px;

            }
        }

        > div {
            padding-left: 15px;
            padding-right: 17px;

            &.links {
                a {
                    width: calc(50% - 1px);
                    height: 34px;
                    margin-right: 1px;
                    font-size: 22px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &.side-nav {
                padding-top: 28px;
                nav {
                    > ul {
                        > li {
                            position: relative;
                            margin-bottom: 0;

                            a {
                                font-size: 22px;
                                padding-bottom: 10px;
                                margin-bottom: 0;
                            }

                            &.sub-menu {
                                > a {

                                    &:after {
                                        @include transition(all,600ms,ease);
                                        font-family: $font-icon;
                                        content: "\E004";
                                        position: absolute;
                                        color: $color-dark;
                                        font-size: 20px;
                                        right: 0;
                                        top: 0;
                                    }
                                }

                                &.open {
                                    > a {
                                        //margin-bottom: 18px;
                                        &:after {
                                            content: "\E003";
                                        }
                                    }

                                    > ul {
                                        opacity: 1;
                                        visibility: visible;
                                        max-height: calc(1000px + 80px);
                                        //padding-top: 18px;
                                    }
                                }

                                > ul {
                                    @include transition(all,1000ms,ease);
                                    opacity: 0;
                                    visibility: hidden;
                                    max-height: 0;
                                    > span {
                                        display: block;
                                        height: 12px;
                                    }

                                    > li {
                                        line-height: 1;
                                        margin-bottom: 10px;
                                        &:last-child {
                                            padding-bottom: 46px;
                                            border-bottom: 1px solid #e9e9e9;
                                        }
                                    }


                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include responsive(small) {
        width: 80%;
        left: -80%;
    }
}
