section.page {

	.container {

		padding:0px;

		@media(max-width: 1023px) {
			padding:0px;
		}

		ul {
			padding-left:15px;
			list-style: disc;
		}
	}

	#googleMap {
		position: fixed !important;
		top:134px;
		left:400px;
		height:calc(100% - 174px);
		width:calc(100% - 400px);

		@media(max-width: 1023px) {
			width:100%;
			height:56vw;
			left:0px;
			top:0px;
			position: relative !important;
		}
	}

	.leftColumn {
		width:400px;
		float:left;
		padding-top:70px;
		padding-left:35px;
		padding-right:45px;
		padding-bottom: 70px;

		&.people {
			@media(max-width: 1023px) {
				padding-bottom: 20px;

				ul.catList {
					display: none;
				}
			}
		}

		&.grid {
			@media(max-width: 1023px) {
				padding-bottom: 20px;

				ul.catList {
					display: none;
				}
			}
		}

		@media(max-width: 1024px) {
			padding-left:20px;
		}

		@media(max-width: 1023px) {
			width:100%;
			padding-top:40px;
			padding-right: 20px;

		}

		ul.catList {
	        float:left;
	        width:100%;
	        list-style: none;
	        padding:0px;

	        li {
	            padding:10px 0px;
	            border-bottom: 1px solid #fff;
	            float:left;
	            width: 100%;

	            a {
	                color:#fff;
	                text-transform: uppercase;
	                float:left;
	                width: 100%;

	                &:hover {
	                    color:#ccc;
	                }
	            }

	        }
	    }
	}

	.rightColumn {
		float:right;
		width:calc(100% - 400px);
		position: relative;

		@media(max-width: 1023px) {
			width:100%;
		}

		.textCarousel {
			position: fixed;
			top:144px;
			left:400px;
			height:120px;
			/*background:#638792;*/
			width: calc(100% - 400px);
			padding:10px 20px;

			@media(max-width: 1024px) {
				top:104px;
			}

			@media(max-width: 1023px) {
				position: relative;
				top:0px;
				left:0px;
				margin-top:0px;
				width: 100%;
				height:140px;
			}
		}

		.heroImage {
			position: fixed;
			top:274px;
			left:400px;
			width:calc(100% - 400px);
			height:calc(100% - 314px);

			@media(max-width: 1024px) {
				top:234px;
				height:calc(100% - 274px);
			}

			@media(max-width: 1023px) {
				top:0px;
				margin-top:0px;
				position: relative;
				height:auto;
				width: auto;
				left:0px;
			}

			img {
				height:auto;
				width:auto;
				max-width:auto;
				max-height: 100%;
			}
		}
	}

}
