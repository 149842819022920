header {
    @include transition(all,600ms,ease);
    width: 100%;
    height: 134px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background:#12172a;

    @media(max-width: 1024px) {
        height:94px;
    }

    @media(max-width: 767px) {
        height:70px;
    }

    div.container {
        padding: 0;
        position: relative;

        a.logo {
            left:25px;
            top:11px;
            float:left;
            position: absolute;
            z-index: 99;

            @media(max-width: 1024px) {
                img {
                    width:290px;
                }
            }

            @media(max-width: 767px) {
                img {
                    width: 200px;
                }
            }

        }

    }

    @include responsive(desktop) {

    }

    @media(max-width: 960px) {

    }

    @media(max-width: 1023px) {

    }

    @media(max-width: 767px) {

    }

    @media(max-width: 640px) {

    }


}
