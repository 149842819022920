section.image {
	div.container {
		@include clear;
		padding-top: 30px;
		padding-bottom: 20px;

		// .image {
		// 	width: 100%;
		// 	height: 385px;
		// }

		img {
			width: 100%;
		}

		p {
			font-size: 12px;
			font-weight: 500;
			color: $color-grey;
			margin-bottom: 0;
		}

		@include responsive(mobile) {
			padding: 0 13px 15px;
		}
	}

}
