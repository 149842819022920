footer {

    height:40px;
    line-height: 40px;
    color:$color-light;
    background:#12172a;
    position: fixed;
    bottom:0px;
    left:0px;
    width: 100%;
    z-index: 999;

    .container {
        text-align: right;
        padding:0px 20px;
    }

    @include responsive(desktop) {

	}

    @include responsive(tablet) {

    }

    @include responsive(mobile) {

    }

    @include responsive(small) {

    }
}
